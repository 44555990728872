<template>
  <div class="home-body main-container min-h-screen flex flex-col justify-between hover:backdrop-blur-sm" style="--bg-image:url('http://cos.hlwwg.com/7djl01csn7u145nhcoo3epgccq-bg-2.jpg')">
    <Header />
    <MainContent />
    <Footer />
  </div>
</template>

<script>
import MainContent from './components/MainContent.vue';
import Footer from './components/Footer.vue';

export default {
    components: {
      MainContent,
      Footer
    },
    data() {
        return { 
            commonClasses: {
                container: 'container mx-auto px-4',
                textZinc600DarkZinc400: 'text-zinc-600 dark:text-zinc-400',
                bgWhiteDarkZinc800: 'bg-white dark:bg-zinc-800',
                shadowLgRoundedLg: 'shadow-lg rounded-lg',
            }
        };
    }
};
</script>

<style scoped>
  
    .home-body {
        background-image: var(--bg-image);
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
</style>