<template>
    <el-carousel style="width: 100%;" :height="height" indicator-position="none">
        <el-carousel-item v-for="item in list" :key="item" name="Name" label="Label">
            <el-image style="width: 100%;height:100%;" :src="typeof item === 'string' ? item : item.response.data.fullurl" :fit="fit" />
        </el-carousel-item>
    </el-carousel>
</template>

<script>
    export default {
        name: 'Carousel',
        props:{
            height:{
                type: String,
                default: '150px',
            },
            fit: {
                type: String,
                default: 'cover'
            },
            list: {
                type: Array,
                default: () => []
            }
        },
        data(){
            return {
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>