<template>
  <div class="chat">

    <div class="left"></div>
    <div class="conter"></div>
    <div class="right">
        <div class="top flex justify-between items-center p px-4">
            <div>Title</div>
            <div @click="$emit('close')">❌</div>
        </div>
        <div class="content"></div>
        <div class="bottom"></div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .chat{
        @apply fixed bg-current w-52 h-32 rounded-xl flex z-10;
        width: 820px;
        height: 500px;
        overflow: hidden;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
        .left{
            background-color: rgb(50, 50, 50);
            width: 55px;
            height: 100%;
        }
        .conter{
            background-color: rgb(238, 238, 238);
            width: 200px;
            height: 100%;
        }
        .right{
            background-color: rgb(259, 259, 259);
            width: calc(820px - 55px - 200px);
            height: 100%;
            .top{
                width: 100%;
                height: 50px;
                border-bottom: 1px solid rgb(209, 209, 209);
            }
            
            .content{
                width: 100%;
                height: calc(100% - 51px - 111px);
            }
            
            .bottom{
                width: 100%;
                height: 110px;
                border-top: 1px solid rgb(209, 209, 209);
            }
        }
    }
</style>