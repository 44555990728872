import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./tailwindcss.css"

const requireComponent = require.context(
    // 其组件目录的相对路径
    "/src/components",
    // 是否查询其子目录
    false,
    // 匹配基础组件文件名的正则表达式
    // /V[A-Z]\w+\.(vue|js)$/
    /\.(vue)$/,
    // 配置是否异步加载
    "sync"
);

const app = createApp(App);
// 全局注册组件
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName.split("/").pop().replace(/\.\w+$/, "");
    app.component(componentName, componentConfig.default || componentConfig);
});
app.use(store);
app.use(router);
app.use(ElementPlus)
app.mount('#app');
