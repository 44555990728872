<template>
    <header class="bg-white dark:bg-zinc-800 p-4 shadow-md" style="background-color: rgba(255,255,255,0.75)">
      <div class="container mx-auto flex justify-between items-center">
        <h1 class="text-xl font-bold cursor-pointer" @click="$router.push('/')">低代码平台</h1>
        <nav>
          <ul class="flex space-x-4">
            <li><a href="#" class="nav-link">首页</a></li>
            <li><a href="#" class="nav-link">功能</a></li>
            <li><a href="#" class="nav-link">定价</a></li>
            <li><a href="#" class="nav-link" @click="is_chat=true">联系我们</a></li>
          </ul>
        </nav>
      </div>
    
      <!-- 聊天框 -->
      <chat class="cursor-pointer" v-if="is_chat" @close="is_chat=false"/>
    </header>
</template>

<script>
    export default {
        data() {
            return { 
                is_chat: false,
                commonClasses: {
                    container: 'container mx-auto px-4',
                    textZinc600DarkZinc400: 'text-zinc-600 dark:text-zinc-400',
                    bgWhiteDarkZinc800: 'bg-white dark:bg-zinc-800',
                    shadowLgRoundedLg: 'shadow-lg rounded-lg',
                }
            };
        }
    }
</script>

<style scoped>
.nav-link {
  @apply text-zinc-600 dark:text-zinc-300 hover:text-blue-500 focus:text-blue-700 focus:outline-none;
}
</style>