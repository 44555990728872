<template>
    <section :class="commonClasses.container + ' mt-8 opacity-75'">
      <div class="text-center">
        <h2 class="text-3xl font-bold mb-4 text-gray-200">快速构建应用，智能构建应用</h2>
        <p :class="commonClasses.textZinc600DarkZinc400 + ' mb-8'"><span class="hover:bg-stone-50">在数字化快速发展的今天，我们深知企业对高效、灵活的应用程序构建平台的需求。因此，我们倾力打造了这款基于Vue组件的低代码开发平台，助力企业快速构建、部署和管理应用。赋予您的团队创建出色应用程序的能力，无需编码。</span></p>
        <button class="cta-button" @click="$router.push({path:'/login'})">立即开始</button>
      </div>
    </section>
    <section :class="commonClasses.container + ' mt-12'">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <FeatureCard title="高效快速" description="采用独特的拖拽式界面设计，轻松创建界面原型，结合Vue组件化特性，减少重复代码，提升开发效率." />
        <FeatureCard title="自动化生成" description="根据界面设计自动生成Vue组件代码和状态管理代码，减少手动编码，提高开发质量." />
        <FeatureCard title="灵活定制" description="提供丰富的组件库和强大的自定义能力，轻松定制符合业务场景的组件." />
      </div>
    </section>
</template>

<script>
    import FeatureCard from './FeatureCard.vue';
    export default {
        components: { FeatureCard },
        data() {
            return { 
                commonClasses: {
                    container: 'container mx-auto px-4',
                    textZinc600DarkZinc400: 'text-zinc-600 dark:text-zinc-400',
                    bgWhiteDarkZinc800: 'bg-white dark:bg-zinc-800',
                    shadowLgRoundedLg: 'shadow-lg rounded-lg',
                }
            };
        }
    }
</script>

<style scoped>
.cta-button {
  @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50;
}
</style>