import http from '@/utils/http.js';
import route from '@/router/index.js'

import { ElMessage } from 'element-plus'

export default {
    namespaced: true,
    state() {
        return {
            
        }
    },
    mutations: {
    },
    actions: {
        // 获取页面列表
        getPageList(context, homePageId) {
            return http.post('/api/pages/getPageList', {homePageId}).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: '查询成功',
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '查询失败');
                return Promise.reject(err);
            })
        },
        // 获取主站点列表
        getHomePageList(context) {
            return http.get('/api/pages/getHomePageList').then(res => {
                ElMessage({
                  message: '查询成功',
                  type: 'success',
                })
                return res;
            }).catch(err => {
                ElMessage.error('查询失败');
                return Promise.reject(err);
            })
        },
        // 添加页面
        addPage(context, {homePageId, path}){
            return http.post('/api/pages/addPage', {homePageId, path}).then(res=>{
                if(res.code == 1){
                    ElMessage({
                        message: '添加成功',
                        type: 'success',
                    });
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err=>{
                ElMessage.error(err.msg || '添加失败');
                return Promise.reject(err);
            })
        },
        // 添加主站点
        addHomePage(context, path) {
            return http.post('/api/pages/addHomePage',{path}).then(res => {
                if(res.code == 0) {
                    return Promise.reject(res);
                }else if(res.code == 1){
                    ElMessage({
                      message: '添加成功',
                      type: 'success',
                    })
                    return res;
                }
            }).catch(err => {
                ElMessage.error(err.msg);
                return Promise.reject(err);
            })
        },
        // 编辑页面
        editPage(context, {id, path}) {
            return http.post('/api/pages/editPage',{id, path}).then(res => {
                if(res.code == 0) {
                    return Promise.reject(res);
                }else if(res.code == 1){
                    ElMessage({
                      message: '修改成功',
                      type: 'success',
                    })
                    return res;
                }
            }).catch(err => {
                ElMessage.error(err.msg || "修改失败");
                return Promise.reject(err);
            })
        },
        // 编辑主站点
        editHomePage(context, {id, path}) {
            return http.post('/api/pages/editHomePage',{id, path}).then(res => {
                if(res.code == 0) {
                    return Promise.reject(res);
                }else if(res.code == 1){
                    ElMessage({
                      message: '修改成功',
                      type: 'success',
                    })
                    return res;
                }
            }).catch(err => {
                ElMessage.error(err.msg || "修改失败");
                return Promise.reject(err);
            })
        },
        // 删除页面
        removePage(context, pageIdList) {
            return http.post('/api/pages/removePage',{pageIdList}).then(res => {
                if(res.code == 0) {
                    return Promise.reject(res);
                }else if(res.code == 1){
                    ElMessage({
                      message: '删除成功',
                      type: 'success',
                    })
                    return res;
                }
            }).catch(err => {
                ElMessage.error(err.msg);
                return Promise.reject(err);
            })
        },
        // 删除主站点
        removeHomePages(context, homePageIdList) {
            return http.post('/api/pages/removeHomePages',{homePageIdList}).then(res => {
                if(res.code == 0) {
                    return Promise.reject(res);
                }else if(res.code == 1){
                    ElMessage({
                      message: '删除成功',
                      type: 'success',
                    })
                    return res;
                }
            }).catch(err => {
                ElMessage.error(err.msg);
                return Promise.reject(err);
            })
        },
        // 获取页面详情
        getPageDetail(context, data) {
            return http.post('/api/pages/getPageDetail',data).then(res => {
                if(res.code == 0) {
                    return Promise.reject(res);
                }else if(res.code == 1){
                    ElMessage({
                      message: '查询成功',
                      type: 'success',
                    })
                    return res;
                }
            }).catch(err => {
                ElMessage.error(err.msg || '查询失败');
                return Promise.reject(err);
            })
        },
        // 保存页面数据
        savePsgeData(context, {id, data}){
            console.log('保存页面数据', id, data);
            return http.post('/api/pages/savePsgeData', {id, data})
            .then(res => {
                if(res.code == 0) {
                    return Promise.reject(res);
                }else if(res.code == 1){
                    ElMessage({
                      message: '保存成功',
                      type: 'success',
                    })
                    return res;
                }
            })
            .catch(err => {
                ElMessage.error(err.msg || '保存失败');
                return Promise.reject();
            });
        }
    }
}