<template>
    <div class="ImgsUp w-full">
        <el-upload
            class="upload-demo"
            ref="upload"
            drag
            action="/api/common/upload"
            :headers="{token}"
            multiple
            :on-success="upSuccess"
            :on-error="upError"
            :before-upload="upBefore"
        >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
                <em>点击上传</em><br/>
                或将文件放到这里
            </div>
            <template #tip>
                <div class="el-upload__tip">
                    小于5Mkb的jpg\png文件
                </div>
            </template>
        </el-upload>
        <div class="waterfall">
            <div v-for="img_url,index in imgUrlList" :data-water="'water-'+index" :key="img_url" class="water upload-demo-show rounded mb-3" @click="removeImg(index)">
                <el-image ref="imgShow" @load="e=>fallImg(e,'water-'+index)" style="width: 70px;" class="rounded m-1" :src="img_url" fit="fill" />
            </div>
        </div>
    </div>
</template>

<script>
    import { UploadFilled } from '@element-plus/icons-vue';
    import { ElLoading, ElMessage } from 'element-plus';
    export default {
        name: 'ImgsUp',
        components: {UploadFilled},
        props: {
            modelValue: {
                type: Array,
                default: ''
            }
        },
        data(){
            return {
                imgUrlList: [],
                row_height_list: []// 存储每列图片高度的数组
            }
        },
        computed: {
            // 用户登录凭证
            token(){
                return this.$store.state?.user?.userInfo ? this.$store.state.user.userInfo.token : ''
            }
        },
        watch: {
            imgUrlList(val){
                this.$emit('update:modelValue', val);
            }
        },
        created() {
            this.imgUrlList = this.modelValue.map(i=>{
                if(typeof i === 'string'){
                    return i;
                }else{
                    return i.response.data.fullurl || '';
                }
            });
        },
        methods: {
            // 瀑布流布局
            waterfallflex(){
                const waterfall_doc = document.querySelector('.waterfall');// 元素容器
                const water_doc_list = document.querySelectorAll('.water');// 获取所有图片元素
                if(water_doc_list.length){
                    const waterfall_width = waterfall_doc.offsetWidth;// 容器宽度
                    const water_width = water_doc_list[0].offsetWidth;// 单张图片的宽度
                    const column = parseInt(waterfall_width/water_width);// 列数
                    let row_height_list = [];// 存储每列高度的数组
                    let minCol;// 距离顶部最小列
                    water_doc_list.forEach(doc=>{
                        if(row_height_list.length < column){
                            minCol = row_height_list.length;
                        }else{
                            // 获取row_height_list的最小值
                            let min = Math.min.apply(null, row_height_list);
                            // 获取最小值的下标
                            let minIndex = row_height_list.indexOf(min);
                            minCol = minIndex;
                        }
                        let left = minCol * water_width;// 当前元素距离左边的距离
                        let top = row_height_list[minCol] || 0;// 当前元素距离顶部的距离
                        doc.style.left = left + 'px';
                        doc.style.top = top + 'px';
                        row_height_list[minCol] = top + doc.offsetHeight;
                    });
                    // 获取row_height_list最大值
                    let max = Math.max.apply(null, row_height_list);
                    waterfall_doc.style.height = max + 'px';
                }
            },
            // 修改图片定位
            fallImg(){
                if(typeof this.timeout == 'number') {
                    clearTimeout(this.timeout)
                    this.timeout = null;
                };
                this.timeout = setTimeout(()=>{
                    this.waterfallflex();
                },300);
            },
            // 上传成功
            upSuccess(res,file) {
                if(res.code != 1) {
                    ElMessage.error(res.msg || '上传失败');
                }else{
                    this.imgUrlList.unshift(res.data.fullurl);
                }
                this.$refs.upload.handleRemove(file);// 移除已上传的文件
                this.loading.close()
            },
            // 上传失败
            upError(err) {
                this.loading.close()
            },
            // 上传前
            upBefore(){
                this.loading = ElLoading.service({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            // 移除图片
            removeImg(index){
                this.imgUrlList.splice(index,1);
                this.fallImg();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .waterfall {
        position: relative;
        .water {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .upload-demo-show {
        width: max-content;
        height: max-content;
        display: grid;
        &:hover{
            display: grid;
            position: relative;
            &::before{
                content: "🗑️";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: rgba(0,0,0,0.5);
                z-index: 1;
                cursor: pointer;
                display: grid;
                align-items: center;
                justify-items: center;
                font-size: 1.5rem;
                @apply rounded;
            }
        }
    }
</style>