<template>
    <div :class="commonClasses.bgWhiteDarkZinc800 + ' ' + commonClasses.shadowLgRoundedLg + ' p-6 hover:shadow-xl transition-shadow duration-300 opacity-75'">
        <h3 class="font-bold text-lg">{{ title }}</h3>
        <p :class="commonClasses.textZinc600DarkZinc400 + ' mt-2'">{{ description }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            description: String,
        },
        data() {
            return { 
                commonClasses: {
                    container: 'container mx-auto px-4',
                    textZinc600DarkZinc400: 'text-zinc-600 dark:text-zinc-400',
                    bgWhiteDarkZinc800: 'bg-white dark:bg-zinc-800',
                    shadowLgRoundedLg: 'shadow-lg rounded-lg',
                }
            };
        }
    }
</script>

<style lang="scss" scoped>
    
</style>