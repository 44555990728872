import axios from "axios";
import $store from "@/store/index";
import { ElMessage } from 'element-plus';

const instance = axios.create({
    baseURL: '',
    // baseURL: 'http://tp.com/',
    timeout: 1000 * 60,
    headers: {'X-Custom-Header': 'foobar'}
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let arr = ['/api/user/login','/api/pages/getPageDetail','/api/user/sendEmailCode','/api/user/register'];

    if (arr.some(i=>i == config.url)){
        return config;
    }else{

        let token = $store.state.user.userInfo?.token;
        if(token){
            config.headers.token = token;
        }else{
            ElMessage.error("请登录后操作");
        }
        return config;
    }
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    if(error.response.data.code == 401){
        ElMessage.error(error.response.data.msg || "请登录后操作");

        // 清除本地登录信息
        $store.commit('user/setUserInfo', null);
        localStorage.removeItem('userinfo');

        window.open("/login", "_self");
    }
    return Promise.reject(error);
});

export default instance;
