import { createStore } from 'vuex'

import user from './modules/user';
import pages from './modules/pages';
import webSite from './modules/webSite';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    pages,
    webSite
  }
})
