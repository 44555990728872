import http from '@/utils/http.js';

import { ElMessage } from 'element-plus'

export default {
    namespaced: true,
    state() {
        return {
            
        }
    },
    mutations: {
    },
    actions: {
        // 获取站点列表
        getSiteList(context) {
            return http.post('/api/web_site/getWebSiteList').then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: '查询成功',
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '查询失败');
                return Promise.reject(err);
            })
        },
        // 获取页面列表
        getPageList(context, site_id) {
            return http.post('/api/web_site/getWebPageList', {site_id}).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: '查询成功',
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '查询失败');
                return Promise.reject(err);
            })
        },
        // 获取单个页面详情
        getPage(context, page_id) {
            return http.post('/api/web_site/getWebPage', {page_id}).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: '查询成功',
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '查询失败');
                return Promise.reject(err);
            })
        },
        // 新增站点
        addWebSite(context, name) {
            return http.post('/api/web_site/addWebSite', {name}).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: '新增成功',
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '操作失败');
                return Promise.reject(err);
            })
        },
        // 新增页面
        addWebPage(context, {path, site_id}) {
            return http.post('/api/web_site/addWebPage ', {path, site_id}).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: '新增成功',
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '操作失败');
                return Promise.reject(err);
            })
        },
        // 修改页面内容
        editPageContent(context, {page_id, content}) {
            return http.post('/api/web_site/editPage ', {page_id, content}).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: res.msg || '修改成功',
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '操作失败');
                return Promise.reject(err);
            })
        },
        // 获取页面详情
        getPageData(context, {site_name, page_path}){
            return http.post('/api/web_site/getPageData', {site_name, page_path})
            .then(res=>{
                if(res.code == 1){
                    return res;
                }else{
                    return Promise.reject(res);
                }
            })
            .catch(err => {
                ElMessage.error(err.msg || '查询失败');
                return Promise.reject(err);
            })
        }
    }
}
