<template>
    <div class="ImgUp w-full">
        <el-upload
            v-if="!imgUrl"
            class="upload-demo"
            ref="upload"
            drag
            action="/api/common/upload"
            :headers="{token}"
            :on-success="upSuccess"
            :on-error="upError"
            :before-upload="upBefore"
        >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text">
                <em>点击上传</em><br/>
                或将文件放到这里
            </div>
            <template #tip>
                <div class="el-upload__tip">
                    小于5Mkb的jpg\png文件
                </div>
            </template>
        </el-upload>
        <div class="upload-demo-show rounded" v-else @click="imgUrl = ''">
            <el-image style="width: 100px;" class="rounded" :src="imgUrl" fit="fill" />
        </div>
    </div>
</template>

<script>
    import { UploadFilled } from '@element-plus/icons-vue';
    import { ElLoading, ElMessage } from 'element-plus';
    export default {
        name: 'ImgUp',
        components: {UploadFilled},
        props: {
            modelValue: {
                type: [String, Array],
                default: ''
            }
        },
        data(){
            return {
                imgUrl: ''
            }
        },
        computed: {
            // 用户登录凭证
            token(){
                return this.$store.state?.user?.userInfo ? this.$store.state.user.userInfo.token : ''
            }
        },
        watch: {
            imgUrl(val){
                this.$emit('update:modelValue', val);
            },
            modelValue: {
                handler(val){
                    if(typeof val === 'string'){
                        this.imgUrl = val;
                    }else{
                        this.imgUrl = val[0]?.response?.data.fullurl || '';
                    }
                },
                immediate: true
            }
        },
        methods: {
            // 上传成功
            upSuccess(res) {
                if(res.code != 1) {
                    ElMessage.error(res.msg || '上传失败');
                }else{
                    this.imgUrl = res.data.fullurl;
                }
                this.$refs.upload.clearFiles();// 清空已上传的文件列表（该方法不支持在 before-upload 中调用）
                this.loading.close()
            },
            // 上传失败
            upError() {
                this.loading.close()
            },
            // 上传前
            upBefore(){
                this.loading = ElLoading.service({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

    .upload-demo-show {
        width: max-content;
        height: max-content;
        display: grid;
        &:hover{
            display: grid;
            position: relative;
            &::before{
                content: "🗑️";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: rgba(0,0,0,0.5);
                z-index: 1;
                cursor: pointer;
                display: grid;
                align-items: center;
                justify-items: center;
                font-size: 1.5rem;
                @apply rounded;
            }
        }
    }
</style>