import http from '@/utils/http.js';
import route from '@/router/index.js';

import { ElMessage, ElMessageBox } from 'element-plus'

export default {
    namespaced: true,
    state() {
        return {
            userInfo: JSON.parse(localStorage.getItem('userinfo')) || null // 用户信息
        }
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        }
    },
    actions: {
        // 用户登录
        login (context, data) {
            return http.post('/api/user/login', data).then(res => {
                if(res.code == 1){
                    localStorage.setItem('userinfo', JSON.stringify(res.data.userinfo));
                    context.commit('setUserInfo', res.data.userinfo);
                    route.push({path: '/'});
                    ElMessage({
                      message: '登录成功',
                      type: 'success',
                    })
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '登录失败');
                console.error(err);
            })
        },
        // 注册-发送邮箱验证码
        sendEmailCode (context, email) {
            return http.post('/api/user/sendEmailCode', {email}).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: res.msg,
                      type: 'success',
                    })
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '发送失败');
                console.error(err);
            })
        },
        // 注册
        register (context, data) {
            return http.post('/api/user/register', data).then(res => {
                if(res.code == 1){
                    ElMessage({
                      message: res.msg || '注册成功',
                      type: 'success',
                    })
                    ElMessageBox.confirm(
                        '是否直接登录并跳转到首页?',
                        '注册成功',
                        {
                            confirmButtonText: '登录',
                            cancelButtonText: '取消',
                            type: 'success',
                        }
                    )
                    .then(() => {
                        localStorage.setItem('userinfo', JSON.stringify(res.data.userinfo));
                        context.commit('setUserInfo', res.data.userinfo);
                        route.push({path: '/'});
                        ElMessage({
                          message: '登录成功',
                          type: 'success',
                        })
                    })
                    
                    return res;
                }else{
                    return Promise.reject(res);
                }
            }).catch(err => {
                ElMessage.error(err.msg || '发送失败');
                console.error(err);
            })
        }
    }
}