<template>
    <div class="BoxModel" :style="cssConstant">
        
    </div>
</template>

<script>
    export default {
        name: 'BoxModel',
        props: {
            width : {
                type: String,
                default: '100px'
            },
            height: {
                type: String,
                default: '100px'
            },
            marginTop: {
                type: String,
                default: '5px'
            },
            marginLeft: {
                type: String,
                default: '5px'
            },
            marginRight: {
                type: String,
                default: '5px'
            },
            marginBottom: {
                type: String,
                default: '5px'
            },
            borderWidth: {
                type: String,
                default: '1px'
            },
            borderStyle: {
                type: String,
                default: 'solid'
            },
            borderColor: {
                type: String,
                default: 'solid'
            },
            borderTopLeftRadius: {
                type: String,
                default: '5px'
            },
            borderTopRightRadius: {
                type: String,
                default: '5px'
            },
            borderBottomLeftRadius: {
                type: String,
                default: '5px'
            },
            borderBottomRightRadius: {
                type: String,
                default: '5px'
            },
            boxPaddingTop: {
                type: String,
                default: '5px'
            },
            boxPaddingLeft: {
                type: String,
                default: '5px'
            },
            boxPaddingRight: {
                type: String,
                default: '5px'
            },
            boxPaddingBottom: {
                type: String,
                default: '5px'
            },
            boxBackgroundColor: {
                type: String,
                default: '#d9ecff'
            },
            boxBackgroundImage: {
                type: [String,Array],
                default: ""
            },
            boxBackgroundSize: {
                type: String,
                default: 'cover'
            },
            boxBackgroundRepeat: {
                type: String,
                default: 'no-repeat'
            },
            boxBackgroundPosition: {
                type: String,
                default: '50% 50%'
            },
            // 阴影 X 偏移量
            boxBoxShadowX: {
                type: String,
                default: '5px'
            },
            // 阴影 Y 偏移量
            boxBoxShadowY: {
                type: String,
                default: '5px'
            },
            // 阴影模糊半径
            boxBoxShadowBlur: {
                type: String,
                default: '5px'
            },
            // 阴影扩散半径
            boxBoxShadowSpread: {
                type: String,
                default: '5px'
            },
            // 阴影颜色
            boxBoxShadowColor: {
                type: String,
                default: 'rgba(0, 0, 0, 0.2)'
            }
        },
        computed: {
            // css常量
            cssConstant(){
                return `
--box-width--: ${this.width};
--box-height--: ${this.height};
--box-margin-top--: ${this.marginTop};
--box-margin-left--: ${this.marginRight};
--box-margin-right--: ${this.marginLeft};
--box-margin-bottom--: ${this.marginBottom};
--box-border-width--: ${this.borderWidth};
--box-border-style--: ${this.borderStyle};
--box-border-color--: ${this.borderColor};
--box-border-top-left-radius--: ${this.borderTopLeftRadius};
--box-border-top-right-radius--: ${this.borderTopRightRadius};
--box-border-bottom-left-radius--: ${this.borderBottomLeftRadius};
--box-border-bottom-right-radius--: ${this.borderBottomRightRadius};
--box-padding-top--: ${this.boxPaddingTop};
--box-padding-left--: ${this.boxPaddingLeft};
--box-padding-right--: ${this.boxPaddingRight};
--box-padding-bottom--: ${this.boxPaddingBottom};
--box-background-color--: ${this.boxBackgroundColor};
--box-background-image--: url(${this.boxBackgroundImage[0]?.response?.data?.fullurl || this.boxBackgroundImage || ""});
--box-backgroundd-size--: ${this.boxBackgroundSize};
--box-background-repeat--: ${this.boxBackgroundRepeat};
--box-background-position--: ${this.boxBackgroundPosition};
--box-box-shadow--: ${this.boxBoxShadowX} ${this.boxBoxShadowY} ${this.boxBoxShadowBlur} ${this.boxBoxShadowSpread} ${this.boxBoxShadowColor};
                `;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .BoxModel{
        width: var(--box-width--);
        height: var(--box-height--);
        margin-top: var(--box-margin-top--);
        margin-left: var(--box-margin-left--);
        margin-right: var(--box-margin-right--);
        margin-bottom: var(--box-margin-bottom--);
        border-width: var(--box-border-width--);
        border-style: var(--box-border-style--);
        border-color: var(--box-border-color--);
        border-top-left-radius: var(--box-border-top-left-radius--);
        border-top-right-radius: var(--box-border-top-right-radius--);
        border-bottom-left-radius: var(--box-border-bottom-left-radius--);
        border-bottom-right-radius: var(--box-border-bottom-right-radius--);
        padding-top: var(--box-padding-top--);
        padding-left: var(--box-padding-left--);
        padding-right: var(--box-padding-right--);
        padding-bottom: var(--box-padding-bottom--);
        background-color: var(--box-background-color--);
        background-image: var(--box-background-image--);
        background-size: var(--box-backgroundd-size--);
        background-repeat: var(--box-background-repeat--);
        background-position: var(--box-background-position--);
        box-shadow: var(--box-box-shadow--);
    }
</style>