import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/reg',
    name: 'Reg',
    component: () => import('../views/Reg')
  },
  {
    path: '/edit-page/:id',
    name: 'EditPage',
    component: () => import('../views/EditPage.vue')
  },
  {
    path: '/edit-preview',
    name: 'EditPreview',
    component: () => import('../views/EditPreview.vue')
  },
  {
    path: '/pages-manage',
    name: 'PagesManage',
    component: () => import('../views/PagesManage')
  },
  {
    path: '/page-detail/:pid',
    name: 'PageDetail',
    component: () => import('../views/PageDetail')
  },
  {
    path: '/user-pre-page/:siteName/:pagePath',
    name: 'UserPrePage',
    component: () => import('../views/UserPrePage')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
