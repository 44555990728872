<template>
    <footer class="bg-zinc-800 text-white p-4 mt-12 opacity-75">
        <div class="container mx-auto text-center">
            <p><a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备2023003930号-2</a></p>
        </div>
    </footer>
</template>

<script>
    export default {
        data(){
            return {
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>